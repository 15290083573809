import Loader from '../loader/Loader';
import MarkdownWrapper from './MarkdownWrapper';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorHandler } from '../../contexts/error-handler/ErrorHandler';
import { useErrorHandlerContext } from '../../contexts/error-handler/ErrorContext';
import { useChatMessageErrorLabels } from '../../hooks/useLabels';
import style from './MarkdownWrapper.module.scss';
import { isString } from '../../helpers/typeHelpers';

const AsyncMarkdownWrapper = ({ url, imageBaseUrl = 'auto' }: { url: string | (() => Promise<string>); imageBaseUrl?: string; }) => {
    const [markdown, setMarkdown] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const errorLabels = useChatMessageErrorLabels();

    const { errorId, registerError, removeError, getError } = useErrorHandlerContext();
    const error = getError(errorId);

    const lock = useRef(false);
    useEffect(() => {
        if (!url || lock.current) return;
        removeError(errorId);

        setIsLoading(true);
        lock.current = true;

        let promise: Promise<string>;
        if (isString(url)) {
            promise = fetch(url).then(response => response.text())
        }
        else {
            promise = url()
        }
        
        promise
            .then(data => {
                setIsLoading(false);
                if (data.startsWith('<!DOCTYPE html>')) {
                    const headline = 'Invalid markdown file received';
                    registerError({ [errorId]: { type: 'notification', headline } });
                }
                setMarkdown(data);
            }).catch((error) => {
                registerError({ [errorId]: { type: 'notification', headline: errorLabels.unknown_error.h!, description: errorLabels.unknown_error.d!, details: error } });
                setIsLoading(false);
            }).finally(() => {
                lock.current = false;
            });
    }, [errorId, url, registerError, removeError, errorLabels]);

    const container = (content: ReactNode) => <div className={style['content-container']}>{content}</div>;

    const calculatedImageBaseUrl = useMemo(() => {
        if (imageBaseUrl === 'auto' && isString(url)) return url.split('/').slice(0, -1).join('/');
        return imageBaseUrl;
    }, [url, imageBaseUrl]);

    if (isLoading) return container(<Loader isRelative isInline={false} />);

    if (error) return container(<ErrorHandler.Notification id={errorId} />);
    return (
        container(<MarkdownWrapper markdown={markdown} imageBaseUrl={calculatedImageBaseUrl} />)
    );
};

export default AsyncMarkdownWrapper;