import useInfo from '../../../hooks/useInfo';
import useLabels from '../../../hooks/useLabels';
import AsyncMarkdownWrapper from '../../../ui/markdown-wrapper/AsyncMarkdownWrapper';
import { Fragment, useState } from 'react';
import { DfTabContent, DfTabHeader, DfTabs } from '@danfoss/mosaic-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnchorHeadline } from './AnchorHeadline';
import { ReleaseTimeline } from './ReleaseTimeline';
import { formatDate } from '../../../helpers/dateHelpers';
import { sortBy } from '../../../helpers/listsHelpers';
import { useAppInfo } from '../../../contexts/app-info/AppInfoContext';
import style from '../Information.module.scss';


export const AiAssistantChanges = () => {
    const labels = useLabels();
    const location = useLocation();
    const navigate = useNavigate();
    const { getPersonaVersionReleaseNotes } = useAppInfo();
    const { allowedPersonaOptions } = useInfo();
    const [activeTab, setActiveTab] = useState('');

    const handleTabChange = (e: CustomEvent) => {
        setActiveTab(e.detail.tabId);
        navigate({ ...location, search: `tab=${e.detail.tabId}`, hash: location.hash }, { replace: true });
    };

    return <div>
        <AnchorHeadline headline={labels.aiAssistantHeadline} />
        <DfTabs className={style['sticky-tabs']} isCustomLinkNavigation={true} activeTabId={activeTab} onActiveTabChange={handleTabChange}>
            {allowedPersonaOptions
                .filter(assistant => !!assistant.key && assistant.versionsHistory?.length) // Advanced Chat Assistant doesn't have a key
                .map((assistant) => {
                    const key = assistant.key;
                    const history = sortBy(assistant.versionsHistory!, 'releaseDate', true);

                    return <Fragment key={key}>
                        <DfTabHeader slot='header' tabId={key}>
                            {assistant.title}
                        </DfTabHeader>
                        <br />
                        <DfTabContent slot='content' tabId={key}>
                            <>
                                <br />
                                {history.map((release, index) => <ReleaseTimeline
                                    key={index}
                                    assistantKey={key}
                                    version={release.version}
                                    date={formatDate(release.releaseDate)}
                                    content={(isOpen) => isOpen && <AsyncMarkdownWrapper url={() => getPersonaVersionReleaseNotes(key, release.version)} />} />)}
                            </>
                        </DfTabContent>
                    </Fragment>;
                })}
        </DfTabs>
    </div>;
};